import React from "react"

import styles from "./checkbox.module.scss"

const Checkbox = props => {
  const { name, children } = props
  const copyProps = { ...props }
  delete copyProps.children
  return (
    <div className={styles.el}>
      <input className={styles.input} type="checkbox" {...copyProps} />
      <label className={styles.label} htmlFor={name}>
        <span className={styles.indicator} aria-hidden="true" />
        {children}
      </label>
    </div>
  )
}

export default Checkbox
